import { template as template_a58cb3e0955f4c75ab73b69569cc8d8d } from "@ember/template-compiler";
const SidebarSectionMessage = template_a58cb3e0955f4c75ab73b69569cc8d8d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
