import { template as template_46f7df26a2be4a8f8f0179ce52cbe387 } from "@ember/template-compiler";
const FKText = template_46f7df26a2be4a8f8f0179ce52cbe387(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
