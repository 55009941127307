import { template as template_1466d3854c4f4356898bf52f43b8bf9f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_1466d3854c4f4356898bf52f43b8bf9f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
